import { Quasar, QVueGlobals } from 'quasar'
import quasarLang from 'quasar/lang/ko-KR'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'
import {App} from "vue";

// quasar 설정 - https://quasar.dev/start/vite-plugin

// export default async ({ app }: { app: App }) => {
//   app.use(Quasar, {
//     plugins: {}, // import Quasar plugins and add here
//     lang: quasarLang,
//   })
// }


//////////////////////////////////////////////////
// 이거 두개가 다 있어야 template 에서 $q 사용가능
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $q: QVueGlobals
  }
}
declare global {
  const $q: QVueGlobals
}
//////////////////////////////////////////////////


export default {
  install(app: App) {
    app.use(Quasar, {
      plugins: {}, // import Quasar plugins and add here
      lang: quasarLang,
    })
  },
};


