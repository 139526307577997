import {createApp} from 'vue'
import config_quasar from './config_quasar'
import MainApp from './MainApp.vue'
import config_global from './config_global'

import router from '../router/router'
import '@common/components-ui/css/components.ui.css'
import '../css/proejct.scss'
import {setAxiosForEnv} from "@/lib/env/config-axios-interceptor.ts"; // https://pinia.vuejs.kr/getting-started.html
import {createHead} from '@unhead/vue' // -- https://km.imdgo.com/w/66140827

// import 'prismjs/themes/prism-tomorrow.css'; // 코드 하이라이팅 테마
// ㄴ 여기 대신 어차피 에디터 내부에서도 사용해야 해서 .css 다운받고 index.html 에 추가함.

// -- 언어 목록 - https://prismjs.com/#supported-languages
import 'prismjs'; // 코드 하이라이팅 script
import 'prismjs/components/prism-bash'; // Java support
import 'prismjs/components/prism-java'; // Java support
import 'prismjs/components/prism-javascript'; // JavaScript support
import 'prismjs/components/prism-sql'; // SQL support
import 'prismjs/components/prism-markup'; // SQL support
import 'prismjs/components/prism-yaml'; // SQL support
import 'prismjs/components/prism-css'; // CSS support
import 'prismjs/components/prism-python'; // Python support


const app = createApp(MainApp)

// 단축키 기능
import VueThreeShortkey from 'vue-three-shortkey'

app.use(VueThreeShortkey, { prevent: ['.except-short-key'] }) // https://github.com/danielelkington/vue-shortkey/blob/release/README.md

// vue-js-modal 모달 플러그인
import VModal from '@common/plugin/vue-js-modal/'
app.use(VModal, {
  dynamic: true,  // 다이나믹 모달 사용여부. 반드시 이 방식으로 해야 IE9 에서도 작동한다
  injectModalsContainer: true, // <modals-container /> 를 선언하지 않아도 자동으로 body 안에 <modals-container /> 를 넣어준다.
  // 반드시 이 방식으로 사용해야 하는이유는
  // 모달안의 모달 호출후 다시 모달을 호출할때 이 옵션이 아니면 작동하지 않기 때문이다.
  // ex) sample 의 컴펌 -> 증표 인쇄 컴펌 -> 다시 sample 컴펌 ( 이 방식이 아니면 작동 안함 )
  dynamicDefaults: {
    // width: "700",
    height: "auto",
    reset: true,
    resizable: true,
    adaptive: true,
    draggable: ".window-header",
    scrollable: true,
    // clickToClose: false,
  }
})

// import IBtn from "@common/components-ui/i-btn.vue";
// app.component('IBtn', IBtn);


// import ComponentsUI from './components-ui' // 아쉽게도 tsx 에서는 이렇게 수동으로 등록해야지만 components-ui 가 사용 가능하다.
// app.use(ComponentsUI)

// import {TestData, TestSubTitle} from "@common/pages_test/test_ui_comps.tsx";
// app.component('TestSubTitle', TestSubTitle)
// app.component('TestData', TestData)

import plugin_env from '@/lib/env/plugin-env.ts'
app.use(plugin_env)
import {createPinia} from 'pinia'
const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router) // https://pinia.vuejs.org/core-concepts/plugins.html#Adding-new-external-properties
})
app.use(pinia)
app.use(config_quasar)
app.use(config_global)
app.use(router)

setAxiosForEnv() // useSessionStore() 부분이 있어서 반드시 app.use(pinia) 이후에 호출해야 한다.

app.use(createHead());

app.mount('#app')


export default app

